body.ant-design ul {
  padding: 0;
  margin: 0;
}
body.ant-design ul > li {
  padding: 0;
  margin: 0;
}
body.ant-design ul > li:before {
  display: none;
}
body.ant-design table th,
body.ant-design table td {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 13px;
}
body.ant-design table tr:first-child td {
  border-top: 0;
}
body.ant-design table td + td {
  border-left: 0;
}
body.ant-design input[type="text"],
body.ant-design input[type="password"],
body.ant-design input[type="date"],
body.ant-design input[type="number"],
body.ant-design input[type="time"] {
  height: auto;
  padding: 4px 11px;
  border-radius: 2px;
}
