body,
html {
  height: auto;
}
body.ant-design .ant-table.ant-table-small .ant-table-title,
html.ant-design .ant-table.ant-table-small .ant-table-title,
body.ant-design .ant-table.ant-table-small .ant-table-footer,
html.ant-design .ant-table.ant-table-small .ant-table-footer,
body.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th,
html.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th,
body.ant-design .ant-table.ant-table-small .ant-table-tbody > tr > td,
html.ant-design .ant-table.ant-table-small .ant-table-tbody > tr > td,
body.ant-design .ant-table.ant-table-small tfoot > tr > th,
html.ant-design .ant-table.ant-table-small tfoot > tr > th,
body.ant-design .ant-table.ant-table-small tfoot > tr > td,
html.ant-design .ant-table.ant-table-small tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
body.ant-design .ant-table-tbody > tr.ant-table-row:hover > td,
html.ant-design .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #fafafa;
}
body.ant-design .ant-table-tbody > tr.ant-table-row > td,
html.ant-design .ant-table-tbody > tr.ant-table-row > td {
  background-color: #fff;
}
body.ant-design .ant-table.ant-table-small .ant-table-expanded-row-fixed,
html.ant-design .ant-table.ant-table-small .ant-table-expanded-row-fixed {
  background-color: #fafafa;
  box-shadow: inset 0 -3px 10px rgba(0, 0, 0, 0.1);
}
body.ant-design .ant-table-tbody > tr.ant-table-row-selected > td,
html.ant-design .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #fff5f0;
}
body.ant-design a:hover,
html.ant-design a:hover {
  color: inherit;
}
body.ant-design a:hover.sweetalert-confirm,
html.ant-design a:hover.sweetalert-confirm {
  color: #fff;
}
body.ant-design .ant-dropdown-menu-item,
html.ant-design .ant-dropdown-menu-item,
body.ant-design .ant-dropdown-menu-submenu-title,
html.ant-design .ant-dropdown-menu-submenu-title {
  padding: 5px 12px;
}
body.ant-design .ant-picker-ranges,
html.ant-design .ant-picker-ranges {
  padding: 4px 12px;
}
body.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th,
html.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th {
  vertical-align: top;
}
body.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th small,
html.ant-design .ant-table.ant-table-small .ant-table-thead > tr > th small {
  display: block;
  font-size: 12px;
}
body.ant-design .ant-list-bordered.ant-list-sm .ant-list-item a:hover,
html.ant-design .ant-list-bordered.ant-list-sm .ant-list-item a:hover {
  color: #f05634;
}
body .ant-table-expand-icon-col,
html .ant-table-expand-icon-col {
  width: 32px;
}
body .ant-table-wrapper.file-list-table:before,
html .ant-table-wrapper.file-list-table:before,
body .ant-table-wrapper.file-list-table:after,
html .ant-table-wrapper.file-list-table:after {
  content: '';
}
body .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper.file-list-table:only-child .ant-table,
html .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper.file-list-table:only-child .ant-table {
  margin: 0;
}
body .ant-table-wrapper.file-list-table .ant-table-thead > tr > th,
html .ant-table-wrapper.file-list-table .ant-table-thead > tr > th {
  background-color: #F3F3F3;
}
body .pg-viewer-wrapper,
html .pg-viewer-wrapper {
  overflow-y: hidden !important;
}
